import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_custom',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'activity',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.select,
          options: [
            'Artisan',
            'Association',
            'EI',
            'EURL',
            'SARL',
            'SAS',
            'SASU',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'cb_mensuel',
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.number,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Adresse de livraison',
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          type: FieldTypeEnum.text,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Complément_adresse',
          isRequired: false,
          isEnabled: true,
          hasHelper: false,
          type: FieldTypeEnum.text,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Code Postal',
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          type: FieldTypeEnum.text,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Ville',
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          type: FieldTypeEnum.text,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Pays',
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          type: FieldTypeEnum.country,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  {
    id: 'pdf_viewer_cgv',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://yavin-acquisition.onboarding.dotfile.com/assets/CGV_CGU_YAVIN.pdf',
    },
  },
];
